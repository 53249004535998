import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import { Col, Card, CardBody } from "reactstrap"
import CountUp from "react-countup"
import ReactApexChart from "react-apexcharts"

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`

export const ImgCont = styled.div``
export const TxtCont = styled.div`
  margin-top: 16px;
`

const MiniWidget = props => {
  return (
    <React.Fragment>
      {props.reports.map((report, key) => (
        <Col md={6} xl={3} key={key}>
          <Card style={{ backgroundColor: "#d8e8f5", borderRadius: "10px" }}>
            <CardBody style={{ padding: "10px 0 10px 30px" }}>
              <Content>
                <ImgCont>
                  <i
                    className={report.icon}
                    style={{ fontSize: "45px", paddingRight: "20px" }}
                  ></i>
                </ImgCont>
                <TxtCont>
                  <h6
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginBottom: "2px",
                    }}
                  >
                    {report.title}{" "}
                  </h6>
                  <p
                    className="text-muted font-size-13"
                    style={{ marginBottom: "0px", fontSize: "14px" }}
                  >
                    {report.subtitle}
                  </p>
                </TxtCont>
              </Content>

              {/* <div className="float-end mt-2">
                <ReactApexChart
                  options={report.options}
                  series={report.series}
                  type={report.charttype}
                  height={report.chartheight}
                  width={report.chartwidth}
                />
              </div>
              <div>
                <h4 className="mb-1 mt-1"><span><CountUp end={report.value} separator="," prefix={report.prefix} suffix={report.suffix} decimals={report.decimal} /></span></h4>
                <p className="text-muted mb-0">{report.title}</p>
              </div>
              <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default MiniWidget

MiniWidget.propTypes = {
  reports: PropTypes.array,
}
