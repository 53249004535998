import React, { useState, useEffect } from "react"
import { Container, Row, Col, CardBody, Card } from "reactstrap"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Rating from "@mui/material/Rating"

import { useAppContext } from "../../context/AppContext"
import CarousalComponent from "../../components/carousal"

export const ItemContainer = styled.div`
  border: 1px solid #f5f6f8;
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 10px;
`
export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Category = styled.div`
  font-weight: 600;
  font-size: 18px !important;
  color: #495057;
`
export const InfoButton = styled.div``

export const CardItems = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`
export const CardItem = styled.div`
  border: 1px solid #7b8190;
  border-radius: 5px;
  height: 250px;
  width: 150px;
  padding: 20px;
  margin: 0 40px 10px 0;
`
export const ImgCont = styled.div`
  border: 1px solid #7b8190;
  border-radius: 5px;
  height: 100px;
  width: 100px;
`
export const Title = styled.div`
  padding-top: 20px;
  font-weight: 600;
  font-size: 18px !important;
  color: #495057;
`
export const Subtitle = styled.div`
  color: #74788d !important;
`
export const Ratings = styled.div``

const CategoriesPage = () => {
  const { url: url } = useParams()

  const { state, dispatch } = useAppContext()
  const [productItems, setProductItems] = useState([])

  useEffect(() => {
    if (state.product.productItems.length > 0) {
      return setProductItems(state.product.productItems)
    }
  }, [state.product.productItems])

  const categories = [
    {
      id: 1,
      title: "Global Top 50",
    },
    {
      id: 2,
      title: "Songs You Might Like",
    },
    {
      id: 3,
      title: "All Time Favourites",
    },
    {
      id: 4,
      title: "Party Songs",
    },
  ]

  const filteredProductItems = productItems.filter(
    productItem => url === productItem.category
  )

  console.log("productItems", productItems)
  console.log("filteredProductItems", filteredProductItems)
  console.log("url", url)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {filteredProductItems.length > 0 ? (
            categories.map(category => (
              <ItemContainer key={category.id}>
                <TopSection>
                  <Category>{category.title}</Category>
                  <InfoButton>See all</InfoButton>
                </TopSection>
                <CarousalComponent productItems={filteredProductItems} />
              </ItemContainer>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                height: "75vh",
              }}
            >
              <h5>Sorry for this category there is no product available</h5>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CategoriesPage
