import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

import theme from "./theme"
import { ThemeProvider } from "@mui/material/styles"

import store from "./store"
import { AppProvider } from "./context/AppContext"

const app = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AppProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProvider>
    </Provider>
  </ThemeProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
