import React, { useState, useEffect } from "react"
import { Container, Row, Col, CardBody, Card } from "reactstrap"
import styled from "styled-components"
import { useAppContext } from "../../context/AppContext"
import CarousalComponent from "../../components/carousal"
import { Link } from "react-router-dom"
import Rating from "@mui/material/Rating"

export const ItemContainer = styled.div`
  border: 1px solid #f5f6f8;
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 10px;
`
export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Category = styled.div`
  font-weight: 600;
  font-size: 18px !important;
  color: #495057;
`
export const InfoButton = styled.div``

export const CardItems = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`
export const CardItem = styled.div`
  border: 1px solid #7b8190;
  border-radius: 5px;
  height: 250px;
  width: 150px;
  padding: 20px;
  margin: 0 40px 10px 0;
`
export const ImgCont = styled.div`
  border: 1px solid #7b8190;
  border-radius: 5px;
  height: 100px;
  width: 100px;
`
export const Title = styled.div`
  padding-top: 20px;
  font-weight: 600;
  font-size: 18px !important;
  color: #495057;
`
export const Subtitle = styled.div`
  color: #74788d !important;
`
export const Ratings = styled.div``

const MyAppsPage = () => {
  const { state, dispatch } = useAppContext()
  const [productItems, setProductItems] = useState([])

  useEffect(() => {
    if (state.product.productItems.length > 0) {
      return setProductItems(state.product.productItems)
    }
  }, [state.product.productItems])

  const categories = [
    {
      id: 1,
      title: "Global Top 50",
    },
    {
      id: 2,
      title: "Songs You Might Like",
    },
    {
      id: 3,
      title: "All Time Favourites",
    },
    {
      id: 4,
      title: "Party Songs",
    },
  ]

  console.log("productItems", productItems)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ItemContainer>
            <CardItems style={{ padding: "0px", justifyContent: "flex-start" }}>
              {productItems.map(item => (
                <div key={item.id} className="product-boxes">
                  <Link
                    to={`/product-detail/${item.id}/${item.title}`}
                    className="text-dark"
                  >
                    <div className="product-img pt-2 px-2">
                      <img
                        src={item.image}
                        alt=""
                        className="img-fluid d-block"
                        style={{
                          borderRadius: "10px",
                          height: "110px",
                          width: "100%",
                        }}
                      />
                    </div>

                    <div className="product-content pt-4 px-2">
                      <h6 className="mb-2">{item.title} </h6>
                      <p
                        className="text-muted font-size-13"
                        style={{ marginBottom: "0px" }}
                      >
                        {item.subtitle}
                      </p>

                      <div className="mt-1 mb-0">
                        <Rating
                          name="read-only"
                          value={item.rating}
                          style={{ fontSize: "15px", paddingBottom: "20px" }}
                          readOnly
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </CardItems>
          </ItemContainer>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MyAppsPage
