import React, { useEffect, useState } from "react"
import { Container, Row, Button } from "reactstrap"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { useAppContext } from "../../context/AppContext"

//Import Components
import MiniWidget from "../Dashboard/mini-widget"
import SalesAnalyticsChart from "../Dashboard/salesanalytics-chart"

export const ItemContainer = styled.div`
  border: 1px solid #f5f6f8;
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 10px;
`
export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Category = styled.div`
  font-weight: 600;
  font-size: 18px !important;
  color: #495057;
`
export const InfoButton = styled.div``

export const CardItems = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`
export const CardItem = styled.div`
  border: 1px solid #7b8190;
  border-radius: 5px;
  height: 250px;
  width: 150px;
  padding: 20px;
  margin: 0 40px 10px 0;
`
export const ImgCont = styled.div`
  border: 1px solid #7b8190;
  border-radius: 5px;
  height: 100px;
  width: 100px;
`
export const Title = styled.div`
  padding-top: 20px;
  font-weight: 600;
  font-size: 18px !important;
  color: #495057;
`
export const Subtitle = styled.div`
  color: #74788d !important;
`
export const Ratings = styled.div``

export const TitleCont = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`
export const ProductTitle = styled.div`
  font-size: 20px;
  font-weight: 800;
`
export const ButtonCont = styled.div``

const ProductDetail = () => {
  const { id: id } = useParams()
  const { state, dispatch } = useAppContext()
  const [productItems, setProductItems] = useState(
    state.product.productItems.length > 0 ? state.product.productItems : []
  )

  useEffect(() => {
    if (state.product.productItems.length > 0) {
      return setProductItems(state.product.productItems)
    }
  }, [state.product.productItems])

  const selectedProduct = productItems.filter(
    productItem => productItem.id == id
  )

  const reports = [
    {
      id: 1,
      icon: "uil-download-alt",
      title: "135k installs",
      subtitle: "12 mb",
    },
    {
      id: 1,
      icon: "uil-dollar-alt",
      title: "$45,678",
      subtitle: "From 895 donars",
    },
    {
      id: 1,
      icon: "uil-github",
      title: "76,986",
      subtitle: "Github stars",
    },
    {
      id: 1,
      icon: "uil-star",
      title: "54,897 Reviews",
      subtitle: "4.74 avg rating",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content" style={{ fontWeight: "800" }}>
        <Container fluid>
          <TitleCont>
            <ProductTitle>{selectedProduct[0].title}</ProductTitle>
            <ButtonCont>
              <Button
                color="primary"
                style={{
                  borderRadius: "25px",
                  height: "33px",
                  lineHeight: "4px",
                  width: "100px",
                  background: "#7370BE",
                  fontWeight: 600,
                }}
              >
                Install
              </Button>
            </ButtonCont>
          </TitleCont>
          <Row>
            <MiniWidget reports={reports} />
          </Row>

          <Row>
            <SalesAnalyticsChart selectedProduct={selectedProduct[0]} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProductDetail
