import React from "react"
import styled from "styled-components"
import Rating from "@mui/material/Rating"
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
} from "reactstrap"
import CountUp from "react-countup"
import bgImage1 from "../../assets/images/a.png"

export const ImgCont = styled.div`
  padding-bottom: 30px;
`
export const TitleCont = styled.div`
  padding-bottom: 30px;
`
export const DescriptionCont = styled.div`
  padding-bottom: 30px;
`
export const MailCont = styled.div`
  padding-bottom: 30px;
`
export const InfoCont = styled.div`
  display: flex;
  justify-content: space-around;
`

export const TextContent = styled.div`
  padding-bottom: 10px;
  text-align: justify;
`

export const NameSection = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Name = styled.div``
export const RatingSec = styled.div`
  margin-top: 20px;
`
export const CommentSection = styled.div``

export const Tab = styled.div``

export const contentTrans = Object.freeze({
  Title: "Ceptart Tyas",
  Subtitle: "Photographer",
  Description: "Passinate about capturing all of lifes important occations",
  Text1:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages",
  Text2: "Why do we use it",
  Text3:
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
})

const SalesAnalyticsChart = ({ selectedProduct }) => {
  const series = [
    {
      name: "Desktops",
      type: "column",
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
    },
    {
      name: "Laptops",
      type: "area",
      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
    },
    {
      name: "Tablets",
      type: "line",
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    },
  ]

  const ReviewsData = [
    {
      id: 1,
      title: "Fachry Nurdiansyah",
      subtitle: "3hrs ago",
      rating: 5,
      comment: "Great work deserves appreciations. This is sos amazing",
    },
    {
      id: 2,
      title: "Fachry Nurdiansyah",
      subtitle: "3hrs ago",
      rating: 3,
      comment: "Great work deserves appreciations. This is sos amazing",
    },
    {
      id: 3,
      title: "Fachry Nurdiansyah",
      subtitle: "3hrs ago",
      rating: 4,
      comment: "Great work deserves appreciations. This is sos amazing",
    },
    {
      id: 4,
      title: "Fachry Nurdiansyah",
      subtitle: "3hrs ago",
      rating: 1,
      comment: "Great work deserves appreciations. This is sos amazing",
    },
  ]

  const options = {
    chart: {
      stacked: !1,
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    colors: ["#5b73e8", "#dfe2e6", "#f1b44c"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: [
      "01/01/2003",
      "02/01/2003",
      "03/01/2003",
      "04/01/2003",
      "05/01/2003",
      "06/01/2003",
      "07/01/2003",
      "08/01/2003",
      "09/01/2003",
      "10/01/2003",
      "11/01/2003",
    ],
    markers: {
      size: 0,
    },

    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Points",
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " points"
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }

  return (
    <React.Fragment>
      <Col xl={9}>
        <Row>
          <Col xl={4}>
            <Card
              style={{
                backgroundColor: "#d8e8f5",
                borderRadius: "10px",
                textAlign: "center",
                height: "fit-content",
              }}
            >
              <CardBody>
                <ImgCont>
                  <div className="product-img pt-2 px-2">
                    <img
                      src={bgImage1}
                      alt=""
                      className="img-fluid d-block"
                      style={{
                        borderRadius: "50%",
                        height: "95px",
                        width: "35%",
                        margin: "auto",
                      }}
                    />
                  </div>
                </ImgCont>
                <TitleCont>
                  <h6
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginBottom: "2px",
                    }}
                  >
                    {selectedProduct.subtitle}{" "}
                  </h6>
                  <p
                    className="text-muted font-size-13"
                    style={{
                      marginBottom: "0px",
                      fontSize: "14px",
                      color: "#7b8190",
                    }}
                  >
                    {contentTrans.Subtitle}
                  </p>
                </TitleCont>
                <DescriptionCont>{contentTrans.Description}</DescriptionCont>
                <MailCont>
                  <h6
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginBottom: "2px",
                    }}
                  >
                    www.photography.com
                  </h6>
                  <p
                    className="text-muted font-size-13"
                    style={{
                      marginBottom: "0px",
                      fontSize: "14px",
                      color: "#7b8190",
                    }}
                  >
                    Austin, Tx
                  </p>
                </MailCont>
                <InfoCont>
                  <Tab>
                    <h6
                      style={{
                        fontWeight: 800,
                        fontSize: "16px",
                        marginBottom: "2px",
                      }}
                    >
                      404{" "}
                    </h6>
                    <p
                      className="text-muted font-size-13"
                      style={{
                        marginBottom: "0px",
                        fontSize: "14px",
                        color: "#7b8190",
                      }}
                    >
                      Supply
                    </p>
                  </Tab>
                  <Tab>
                    <h6
                      style={{
                        fontWeight: 800,
                        fontSize: "16px",
                        marginBottom: "2px",
                      }}
                    >
                      13k{" "}
                    </h6>
                    <p
                      className="text-muted font-size-13"
                      style={{
                        marginBottom: "0px",
                        fontSize: "14px",
                        color: "#7b8190",
                      }}
                    >
                      Price
                    </p>
                  </Tab>
                  <Tab>
                    <h6
                      style={{
                        fontWeight: 800,
                        fontSize: "16px",
                        marginBottom: "2px",
                      }}
                    >
                      325{" "}
                    </h6>
                    <p
                      className="text-muted font-size-13"
                      style={{
                        marginBottom: "0px",
                        fontSize: "14px",
                        color: "#7b8190",
                      }}
                    >
                      Holders
                    </p>
                  </Tab>
                </InfoCont>
              </CardBody>
            </Card>
          </Col>
          <Col xl={8}>
            <Card
              style={{
                backgroundColor: "#d8e8f5",
                borderRadius: "10px",
                height: "fit-content",
              }}
            >
              <CardBody>
                <TextContent style={{ paddingBottom: "20px" }}>
                  {contentTrans.Text1}
                </TextContent>
                <TextContent>{contentTrans.Text2}</TextContent>
                <TextContent>{contentTrans.Text3}</TextContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={12} style={{ display: "flex", flexDirection: "row" }}>
            <Col xl={3}>
              <div className="product-img pt-2 px-2">
                <img
                  src={bgImage1}
                  alt=""
                  className="img-fluid d-block"
                  style={{
                    borderRadius: "10px",
                    height: "210px",
                    width: "100%",
                  }}
                />
              </div>
            </Col>
            <Col xl={3}>
              <div className="product-img pt-2 px-2">
                <img
                  src={bgImage1}
                  alt=""
                  className="img-fluid d-block"
                  style={{
                    borderRadius: "10px",
                    height: "210px",
                    width: "100%",
                  }}
                />
              </div>
            </Col>
            <Col xl={3}>
              <div className="product-img pt-2 px-2">
                <img
                  src={bgImage1}
                  alt=""
                  className="img-fluid d-block"
                  style={{
                    borderRadius: "10px",
                    height: "210px",
                    width: "100%",
                  }}
                />
              </div>
            </Col>
            <Col xl={3}>
              <Row>
                <Col xl={6} style={{ paddingRight: "0px" }}>
                  <div className="product-img pt-2">
                    <img
                      src={bgImage1}
                      alt=""
                      className="img-fluid d-block"
                      style={{
                        borderRadius: "10px",
                        height: "100px",
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
                <Col xl={6} style={{ paddingRight: "0px" }}>
                  <div className="product-img pt-2">
                    <img
                      src={bgImage1}
                      alt=""
                      className="img-fluid d-block"
                      style={{
                        borderRadius: "10px",
                        height: "100px",
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} style={{ paddingRight: "0px" }}>
                  <div className="product-img pt-2 ">
                    <img
                      src={bgImage1}
                      alt=""
                      className="img-fluid d-block"
                      style={{
                        borderRadius: "10px",
                        height: "100px",
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
                <Col xl={6} style={{ paddingRight: "0px" }}>
                  <div className="product-img pt-2 ">
                    <img
                      src={bgImage1}
                      alt=""
                      className="img-fluid d-block"
                      style={{
                        borderRadius: "10px",
                        height: "100px",
                        width: "100%",
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Col>

      <Col xl={3}>
        {ReviewsData.map(item => (
          <Card
            key={item.id}
            style={{ backgroundColor: "#d8e8f5", borderRadius: "10px" }}
          >
            <CardBody>
              <NameSection>
                <Name>
                  <h6
                    style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginBottom: "2px",
                    }}
                  >
                    {item.title}{" "}
                  </h6>
                  <p
                    className="text-muted font-size-13"
                    style={{
                      marginBottom: "0px",
                      fontSize: "14px",
                      color: "#7b8190",
                    }}
                  >
                    {item.subtitle}
                  </p>
                </Name>
                <RatingSec>
                  <Rating
                    name="read-only"
                    value={item.rating}
                    style={{
                      fontSize: "15px",
                      paddingBottom: "20px",
                      color: "#495057",
                    }}
                    readOnly
                  />
                </RatingSec>
              </NameSection>
              <CommentSection>{item.comment}</CommentSection>
            </CardBody>
          </Card>
        ))}
      </Col>

      {/* <div className="float-end">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="text-reset" id="dropdownMenuButton5" caret href="#">
                                <span className="fw-semibold">Sort By:</span> <span className="text-muted">Yearly<i className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href="#">Monthly</DropdownItem>
                                <DropdownItem href="#">Yearly</DropdownItem>
                                <DropdownItem href="#">Weekly</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <CardTitle className="mb-4 h4">Sales Analytics</CardTitle>
                    <div className="mt-1">
                        <ul className="list-inline main-chart mb-0">
                            <li className="list-inline-item chart-border-left me-0 border-0">
                                <h3 className="text-primary"><span><CountUp end={2371} separator="," prefix="$" /></span><span className="text-muted d-inline-block font-size-15 ms-3">Income</span></h3>
                            </li>{" "}
                            <li className="list-inline-item chart-border-left me-0">
                                <h3><span data-plugin="counterup"><CountUp end={258} /></span><span className="text-muted d-inline-block font-size-15 ms-3">Sales</span>
                                </h3>
                            </li>{" "}
                            <li className="list-inline-item chart-border-left me-0">
                                <h3><span data-plugin="counterup"><CountUp end={3.6} decimals={1} suffix="%" /></span><span className="text-muted d-inline-block font-size-15 ms-3">Conversation Ratio</span></h3>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-3">
                        <ReactApexChart
                            options={options}
                            series={series}
                            height="339"
                            type="line"
                            className="apex-charts"
                        />
                    </div> */}
    </React.Fragment>
  )
}

export default SalesAnalyticsChart
