import bgImage1 from "../../assets/images/a.png"
import bgImage2 from "../../assets/images/b.png"
import bgImage3 from "../../assets/images/c.png"
import bgImage4 from "../../assets/images/d.png"
import bgImage5 from "../../assets/images/e.png"

const navBarTabs = [
  {
    id: 1,
    icon: "uil-palette",
    title: "Arts & Design",
    url: "arts",
  },
  {
    id: 2,
    icon: "uil-car",
    title: "Auto & Vechicles",
    url: "auto",
  },
  {
    id: 3,
    icon: "uil-calender",
    title: "Beauty",
    url: "beauty",
  },
  {
    id: 4,
    icon: "uil-books",
    title: "Books & Reference",
    url: "books",
  },
  {
    id: 5,
    icon: "uil-suitcase",
    title: "Business",
    url: "business",
  },
  {
    id: 6,
    icon: "uil-calender",
    title: "Comics",
    url: "comics",
  },
  {
    id: 7,
    icon: "uil-award",
    title: "Education",
    url: "education",
  },
  {
    id: 8,
    icon: "uil-tv-retro",
    title: "Entertainment",
    url: "entertainment",
  },
  {
    id: 9,
    icon: "uil-calender",
    title: "Events",
    url: "events",
  },
  {
    id: 10,
    icon: "uil-money-stack",
    title: "Finance",
    url: "finance",
  },
  {
    id: 11,
    icon: "uil-calender",
    title: "Games",
    url: "games",
  },
  {
    id: 12,
    icon: "uil-heart-medical",
    title: "Health & Fitness",
    url: "health",
  },
  {
    id: 13,
    icon: "uil-home",
    title: "House & Home",
    url: "house",
  },
  {
    id: 14,
    icon: "uil-cloud-heart",
    title: "Lifestyle",
    url: "lifestyle",
  },
  {
    id: 15,
    icon: "uil-music",
    title: "Music & Audio",
    url: "music",
  },
  {
    id: 16,
    icon: "uil-newspaper",
    title: "News & Magazine",
    url: "news",
  },
  {
    id: 17,
    icon: "uil-football-american",
    title: "Sports",
    url: "sports",
  },
  {
    id: 18,
    icon: "uil-plane-departure",
    title: "Travel & Local",
    url: "travel",
  },
]

const data = [
  {
    id: 1,
    title: "Told You SO",
    subtitle: "Precommerce",
    image: bgImage1,
    rating: 5,
    category: "arts",
  },
  {
    id: 2,
    title: "The Adventure",
    subtitle: "Precommerce",
    image: bgImage2,
    rating: 4,
    category: "arts",
  },
  {
    id: 3,
    title: "Paradise City",
    subtitle: "Precommerce",
    image: bgImage3,
    rating: 3,
    category: "arts",
  },
  {
    id: 4,
    title: "18 and Life",
    subtitle: "Precommerce",
    image: bgImage4,
    rating: 1,
    category: "arts",
  },
  {
    id: 5,
    title: "Last Hope",
    subtitle: "Precommerce",
    image: bgImage5,
    rating: 2,
    category: "arts",
  },
  {
    id: 6,
    title: "Basket Case",
    subtitle: "Precommerce",
    image: bgImage2,
    rating: 2,
    category: "arts",
  },
  {
    id: 7,
    title: "Told You SO",
    subtitle: "Precommerce",
    image: bgImage1,
    rating: 2,
    category: "arts",
  },
]

localStorage.setItem("product", JSON.stringify(data))

export { navBarTabs }
