import React, { useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import { useFormik, Formik } from "formik"
import styled from "styled-components"
import * as Yup from "yup"
import {
  Card,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Collapse,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { useAppContext } from "../../context/AppContext"

export const StyledInput = styled(({ ...other }) => <Input {...other} />)(
  ({ theme }) => ({
    borderRadius: "20px",
    width: "90%",
    height: "40px",
    color: "#7C8DB5",
    fontWeight: "800",
  })
)

export const StyledLabel = styled(({ ...other }) => <Label {...other} />)(
  ({ theme }) => ({
    fontWeight: "800",
    fontSize: "16px",
    color: "#303E65",
  })
)

export const ErrorLabel = styled(({ ...other }) => <Label {...other} />)(
  ({ theme }) => ({
    fontWeight: "800",
    fontSize: "12px",
    color: "#ea3515",
  })
)

export const StyledGrid = styled(({ ...other }) => <Grid {...other} />)(
  ({ theme }) => ({
    padding: "30px",
  })
)

const ProductSchema = Yup.object().shape({
  productName: Yup.string().required("Please Enter Your Product Name"),
  ownerName: Yup.string().required("Please Enter Your Owner Name"),
  // category: Yup.string().required("Please Enter Your Category"),
  // productImage: Yup.string().required("Please Enter Your Product Image"),
})

const AddProduct = () => {
  const history = useHistory()

  const { state, dispatch } = useAppContext()
  const [productItems, setProductItems] = useState([])

  const [selectedFiles, setselectedFiles] = useState([])
  const [productImg, setProductImg] = useState("")

  const toggleAddproduct = () => setIsOpenAddproduct(!isOpenAddproduct)

  const [isOpenMetadata, setIsOpenMetadata] = useState(false)

  const toggleMetadata = () => setIsOpenMetadata(!isOpenMetadata)

  useEffect(() => {
    if (state.product.productItems.length > 0) {
      return setProductItems(state.product.productItems)
    }
  }, [state.product.productItems])

  const categories = [
    {
      id: 1,
      title: "Arts & Design",
    },
    {
      id: 2,
      title: "Auto & Vechicles",
    },
    {
      id: 3,
      title: "Beauty",
    },
    {
      id: 4,
      title: "Books & Reference",
    },
    {
      id: 5,
      title: "Business",
    },
    {
      id: 6,
      title: "Comics",
    },
    {
      id: 7,
      title: "Education",
    },
    {
      id: 8,
      title: "Entertainment",
    },
    {
      id: 9,
      title: "Events",
    },
    {
      id: 10,
      title: "Finance",
    },
    {
      id: 11,
      title: "Games",
    },
    {
      id: 12,
      title: "Health & Fitness",
    },
    {
      id: 13,
      title: "House & Home",
    },
    {
      id: 14,
      title: "Lifestyle",
    },
    {
      id: 15,
      title: "Music & Audio",
    },
    {
      id: 16,
      title: "News & Magazine",
    },
    {
      id: 17,
      title: "Sports",
    },
    {
      id: 18,
      title: "Travel & Local",
    },
  ]

  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" },
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
    setProductImg(files[0].preview)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="mb-3">
          <StyledLabel
            htmlFor="Title"
            style={{ fontSize: "24px", paddingLeft: "30px" }}
          >
            Product Detail
          </StyledLabel>
        </div>

        <Formik
          initialValues={{ productName: "", ownerName: "" }}
          validationSchema={ProductSchema}
          onSubmit={(values, isSubmitting) => {
            console.log("isSubmitting", isSubmitting)

            const newProduct = {
              id: productItems.length + 1,
              title: values["productName"],
              subtitle: values["ownerName"],
              image: productImg,
              category: values["category"],
            }

            console.log("newProduct", newProduct)

            // save new product
            dispatch({
              type: "ADD",
              payload: newProduct,
            })

            dispatch({
              type: "PROD ADDED",
              payload: true,
            })

            history.push("/dashboard")
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <StyledGrid container spacing={4}>
                <Grid item xs={6}>
                  <div className="mb-3">
                    <StyledLabel htmlFor="productName">
                      Product Name
                    </StyledLabel>
                    <StyledInput
                      id="productName"
                      name="productName"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.productName}
                    />
                    <ErrorLabel>
                      {errors.productName &&
                        touched.productName &&
                        errors.productName}
                    </ErrorLabel>
                  </div>
                  <div className="mb-3">
                    <StyledLabel>Product Owner Name</StyledLabel>
                    <StyledInput
                      id="ownerName"
                      name="ownerName"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ownerName}
                    />
                    <ErrorLabel>
                      {errors.ownerName &&
                        touched.ownerName &&
                        errors.ownerName}
                    </ErrorLabel>
                  </div>
                  <div className="mb-3">
                    <StyledLabel htmlFor="productname">
                      Product Image
                    </StyledLabel>

                    <Form>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted uil uil-cloud-upload"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>
                    </Form>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="mb-3">
                    <StyledLabel htmlFor="productname">Wallet Id</StyledLabel>
                    <StyledInput
                      id="walletId"
                      name="walletId"
                      type="text"
                      className="form-control"
                      value="0x56c4BAAFafFd2c8d72A10dAfeDe13a5538a3d7eD"
                      disabled
                    />
                    <ErrorLabel></ErrorLabel>
                  </div>
                  <div className="mb-3">
                    <StyledLabel className="control-label">
                      Category
                    </StyledLabel>
                    <StyledInput
                      name="category"
                      type="select"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.category || ""}
                    >
                      {categories.map(category => (
                        <option
                          key={category.id}
                          style={{ color: "#303E65", fontWeight: "800" }}
                        >
                          {category.title}
                        </option>
                      ))}
                    </StyledInput>
                    <ErrorLabel></ErrorLabel>
                  </div>
                  <div className="mb-3">
                    <StyledLabel htmlFor="productname">Product Zip</StyledLabel>

                    <Form>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted uil uil-cloud-upload"></i>
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Form>
                  </div>
                </Grid>
                <div className="mb-5" style={{ margin: "auto" }}>
                  <button
                    disabled={isSubmitting}
                    className="btn btn-success"
                    // onClick={handleSave}
                    type="submit"
                    style={{
                      borderRadius: "25px",
                      height: "40px",
                      lineHeight: "4px",
                      width: "120px",
                      background: "rgb(115, 112, 190)",
                      fontWeight: "600",
                      border: "none",
                    }}
                  >
                    Save
                  </button>
                </div>
              </StyledGrid>
            </form>
          )}
        </Formik>
        <Grid item xs={6}></Grid>
      </div>
    </React.Fragment>
  )
}

export default AddProduct
