import React, { createContext, useContext, useMemo, useReducer } from "react"

import rootReducer from "../store/reducers"
import { initialState } from "../store/reducers"

const AppContext = createContext(null)

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState)

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)

export default AppContext
