import React, { useState, createContext, useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import styled from "styled-components"
import {
  Card,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Collapse,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"

import {
  productItems,
  action,
  addProductReducer,
} from "../../reducers/addProduct"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useAppContext } from "../../context/AppContext"

export const StyledInput = styled(({ ...other }) => <Input {...other} />)(
  ({ theme }) => ({
    borderRadius: "20px",
    width: "75%",
    height: "40px",
  })
)

export const StyledLabel = styled(({ ...other }) => <Label {...other} />)(
  ({ theme }) => ({
    fontWeight: "800",
    fontSize: "16px",
    color: "#303E65",
  })
)

export const StyledGrid = styled(({ ...other }) => <Grid {...other} />)(
  ({ theme }) => ({
    padding: "30px",
  })
)

const AddProduct = () => {
  const history = useHistory()
  const { dispatch } = useAppContext()

  const [productName, setProductName] = useState("")
  const [category, setCategory] = useState("")
  const [productImg, setProductImg] = useState("")
  const [manufacturerName, setManufacturerName] = useState("")

  const [selectedFiles, setselectedFiles] = useState([])
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)

  const [isOpenAddproduct, setIsOpenAddproduct] = useState(false)

  const toggleAddproduct = () => setIsOpenAddproduct(!isOpenAddproduct)

  const [isOpenMetadata, setIsOpenMetadata] = useState(false)

  const toggleMetadata = () => setIsOpenMetadata(!isOpenMetadata)

  const categories = [
    {
      id: 1,
      title: "Arts & Design",
    },
    {
      id: 2,
      title: "Auto & Vechicles",
    },
    {
      id: 3,
      title: "Beauty",
    },
    {
      id: 4,
      title: "Books & Reference",
    },
    {
      id: 5,
      title: "Business",
    },
    {
      id: 6,
      title: "Comics",
    },
    {
      id: 7,
      title: "Education",
    },
    {
      id: 8,
      title: "Entertainment",
    },
    {
      id: 9,
      title: "Events",
    },
    {
      id: 10,
      title: "Finance",
    },
    {
      id: 11,
      title: "Games",
    },
    {
      id: 12,
      title: "Health & Fitness",
    },
    {
      id: 13,
      title: "House & Home",
    },
    {
      id: 14,
      title: "Lifestyle",
    },
    {
      id: 15,
      title: "Music & Audio",
    },
    {
      id: 16,
      title: "News & Magazine",
    },
    {
      id: 17,
      title: "Sports",
    },
    {
      id: 18,
      title: "Travel & Local",
    },
  ]

  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" },
  ]

  function handleAcceptedFiles(files) {
    console.log("file", files)
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
    setProductImg(files[0].preview)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSave = () => {
    const obj = {
      id: productItems.length + 1,
      title: productName,
      subtitle: manufacturerName,
      image: productImg,
      category: category,
    }

    dispatch({
      type: "ADD",
      payload: obj,
    })

    history.push("/dashboard")
  }

  const handleSaveProductName = value => {
    setProductName(value)
  }

  const handleSaveManufacturerName = value => {
    setManufacturerName(value)
  }

  const handleSaveCategory = value => {
    setCategory(value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="mb-3">
          <StyledLabel
            htmlFor="Title"
            style={{ fontSize: "24px", paddingLeft: "30px" }}
          >
            Profile Detail
          </StyledLabel>
        </div>

        <StyledGrid container spacing={4}>
          <Grid item xs={6}>
            <Form>
              <div className="mb-5">
                <StyledLabel htmlFor="productname">Account Name</StyledLabel>
                <StyledInput
                  id="accname"
                  name="accname"
                  type="text"
                  className="form-control"
                  onChange={e => handleSaveAccName(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <StyledLabel>Email Id</StyledLabel>
                <StyledInput
                  id="email"
                  name="email"
                  type="email"
                  className="form-control"
                  onChange={e => handleSaveEmail(e.target.value)}
                />
              </div>
            </Form>
          </Grid>
          <Grid item xs={6}>
            <div className="mb-5">
              <StyledLabel htmlFor="productname">Wallet Id</StyledLabel>
              <StyledInput
                id="walletId"
                name="walletId"
                type="text"
                className="form-control"
                value="0x56c4BAAFafFd2c8d72A10dAfeDe13a5538a3d7eD"
                disabled
              />
            </div>
            <div className="mb-5">
              <StyledLabel className="control-label">Social Link</StyledLabel>
              <StyledInput
                id="link"
                name="link"
                type="text"
                className="form-control"
                onChange={e => handleSaveLink(e.target.value)}
              />
            </div>
          </Grid>
          <div className="mb-5" style={{ margin: "auto" }}>
            <button
              className="btn btn-success"
              onClick={handleSave}
              style={{
                borderRadius: "25px",
                height: "40px",
                lineHeight: "4px",
                width: "120px",
                background: "rgb(115, 112, 190)",
                fontWeight: "600",
                border: "none",
              }}
            >
              Save
            </button>
          </div>
        </StyledGrid>
      </div>
    </React.Fragment>
  )
}

export default AddProduct
