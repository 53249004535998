import "semantic-ui-css/semantic.min.css"
import "react-multi-carousel/lib/styles.css"
import UAParser from "ua-parser-js"
import React, { Fragment } from "react"
import Section from "./section"
import CarousalItem from "./carousal"

const CarousalComponent = ({ deviceType, productItems }) => {
  return (
    <Fragment>
      <Section>
        <CarousalItem deviceType={deviceType} productItems={productItems} />
      </Section>
    </Fragment>
  )
}
CarousalComponent.getInitialProps = ({ req }) => {
  let userAgent
  if (req) {
    userAgent = req.headers["user-agent"]
  } else {
    userAgent = navigator.userAgent
  }
  const parser = new UAParser()
  parser.setUA(userAgent)
  const result = parser.getResult()
  const deviceType = (result.device && result.device.type) || "desktop"
  return { deviceType }
}
export default CarousalComponent
