import { productItems } from "../../reducers/addProduct"
import bgImage1 from "../../assets/images/a.png"
import bgImage2 from "../../assets/images/b.png"
import bgImage3 from "../../assets/images/c.png"
import bgImage4 from "../../assets/images/d.png"
import bgImage5 from "../../assets/images/e.png"

export const ADD_PROD_INIT_STATE = {
  productItems: [
    {
      id: 1,
      title: "Told You SO",
      subtitle: "Precommerce",
      image: bgImage1,
      rating: 5,
      category: "arts",
    },
    {
      id: 2,
      title: "The Adventure",
      subtitle: "Precommerce",
      image: bgImage2,
      rating: 4,
      category: "auto",
    },
    {
      id: 3,
      title: "Paradise City",
      subtitle: "Precommerce",
      image: bgImage3,
      rating: 3,
      category: "beauty",
    },
    {
      id: 4,
      title: "18 and Life",
      subtitle: "Precommerce",
      image: bgImage4,
      rating: 1,
      category: "books",
    },
    {
      id: 5,
      title: "Last Hope",
      subtitle: "Precommerce",
      image: bgImage5,
      rating: 2,
      category: "arts",
    },
    {
      id: 6,
      title: "Basket Case",
      subtitle: "Precommerce",
      image: bgImage2,
      rating: 2,
      category: "auto",
    },
    {
      id: 7,
      title: "Told You SO",
      subtitle: "Precommerce",
      image: bgImage1,
      rating: 2,
      category: "arts",
    },
  ],
  success: false,
}

export const action = {
  type: "ADD",
}

export const addProductReducer = (state = ADD_PROD_INIT_STATE, action) => {
  switch (action.type) {
    case "ADD":
      return {
        ...state,
        productItems: [...state.productItems, action.payload],
      }

    case "PROD ADDED":
      return {
        ...state,
        success: action.payload,
      }

    case "PROD ADDED DONE":
      return {
        ...state,
        success: action.payload,
      }
    default:
      return state
  }
}
