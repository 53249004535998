import React, { Component } from "react"
import Carousel from "react-multi-carousel"
import { Image } from "semantic-ui-react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import Rating from "@mui/material/Rating"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    // paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
}

const CarousalItem = ({ deviceType, productItems }) => {
  return (
    <div>
      <Carousel
        className="StyledCarousel"
        deviceType={deviceType}
        itemClass="image-item"
        partialVisbile
        responsive={responsive}
        keyBoardControl={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        autoPlay={false}
        draggable={true}
        showDots={false}
        arrows={productItems.length > 7 ? true : false}
        shouldResetAutoplay={true}
      >
        {productItems.map(item => (
          <div key={item.id} className="product-boxes">
            <Link
              to={`/product-detail/${item.id}/${item.title}`}
              className="text-dark"
            >
              <div className="product-img pt-2 px-2">
                <img
                  src={item.image}
                  alt=""
                  className="img-fluid d-block"
                  style={{
                    borderRadius: "10px",
                    height: "95px",
                    width: "95%",
                  }}
                />
              </div>

              <div className="product-content pt-4 px-2">
                <h6 className="mb-2">{item.title} </h6>
                <p
                  className="text-muted font-size-13"
                  style={{ marginBottom: "0px" }}
                >
                  {item.subtitle}
                </p>

                <div className="mt-1 mb-0">
                  <Rating
                    name="read-only"
                    value={item.rating}
                    style={{
                      fontSize: "15px",
                      paddingBottom: "20px",
                    }}
                    readOnly
                  />
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default CarousalItem
