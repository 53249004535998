import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 375,
      sm: 420,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
})
export default theme
