export const productItems = []

export const action = {
  type: "ADD",
  description: "add-items",
}

export const addProductReducer = (productItems, action, newItem) => {
  switch (action.type) {
    case "ADD":
      return productItems.push(newItem)
    default:
      return productItems
  }
}
